#field_assigned_to .gravatar-container {
  display: inline;
  margin-right: 2px;
}
#field_assigned_to .gravatar-container img {
  vertical-align: middle;
}
#new_ticket_form_container {
  margin: 1em auto 0 auto;
  max-width: 60em;
  padding-top: 82px;
  position: relative;
}
#new_ticket_form_container:before {
  background-image: url('../images/bug/below.png');
  background-repeat: no-repeat;
  content: "";
  display: block;
  width: 112px;
  height: 147px;
  position: absolute;
  right: 70px;
  top: 0;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
  #new_ticket_form_container:before {
    background-image: url("../images/bug/below@2x.png");
    background-size: 112px 147px;
  }
}
#new_ticket_form_container:after {
  background-image: url('../images/bug/above.png');
  background-repeat: no-repeat;
  background-position: 17px 0;
  content: "";
  display: block;
  position: absolute;
  top: 73px;
  right: 70px;
  width: 112px;
  height: 21px;
  z-index: 2;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
  #new_ticket_form_container:after {
    background-image: url("../images/bug/above@2x.png");
    background-size: 78px 21px;
  }
}
#new_ticket_form_container.has-errors:before {
  background-image: url('../images/bug/below-sad.png');
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
  #new_ticket_form_container.has-errors:before {
    background-image: url("../images/bug/below-sad@2x.png");
    background-size: 112px 147px;
  }
}
#new_ticket_form_container.has-errors:after {
  background-image: url('../images/bug/above-sad.png');
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
  #new_ticket_form_container.has-errors:after {
    background-image: url("../images/bug/above-sad@2x.png");
    background-size: 78px 21px;
  }
}
#new_ticket_form_container.success:before {
  background-image: url('../images/bug/below-happy.png');
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
  #new_ticket_form_container.success:before {
    background-image: url("../images/bug/below-happy@2x.png");
    background-size: 112px 147px;
  }
}
#new_ticket_form_container #new_ticket_form {
  background: #ECF7FF;
  border: 1px #BBB solid;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 2em;
  position: relative;
  width: 100%;
}
#new_ticket_form_container #new_ticket_form input[type=text] {
  box-sizing: border-box;
  padding: 0.6em;
}
#new_ticket_form_container #new_ticket_form input[type=text],
#new_ticket_form_container #new_ticket_form textarea {
  width: 100%;
}
#new_ticket_form_container #new_ticket_form.ready #ticket_template_notice {
  /*
       * NOTE: If you change this, you must change
       * Splat.NewTicketPageView.NOTICE_ANIMATE_SPEED_OPEN_MSEC.
       */
  background: #f6fbff;
  border-top: 2px #D5C008 solid;
  border-bottom: 2px #D5C008 solid;
  margin: 2em -2em;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: 0.35s opacity linear, 0.35s height linear;
}
#new_ticket_form_container #new_ticket_form.ready #ticket_template_notice.open {
  opacity: 1;
}
#new_ticket_form_container #new_ticket_form.ready #ticket_template_notice .ticket-template-notice-body {
  line-height: 1.5;
  padding: 2em;
  opacity: 0;
  /* This must come after &.transitioning above. */
}
#new_ticket_form_container #new_ticket_form.ready #ticket_template_notice .ticket-template-notice-body.transitioning {
  position: absolute;
  transition: 0.35s opacity linear, 0.35s top linear;
}
#new_ticket_form_container #new_ticket_form.ready #ticket_template_notice .ticket-template-notice-body.current {
  opacity: 1;
  top: 0;
}
#new_ticket_form_container #new_ticket_form.ready #ticket_template_notice .ticket-template-notice-body p {
  margin: 1em 0 0 0;
}
#new_ticket_form_container #new_ticket_form.ready #ticket_template_notice .ticket-template-notice-body p:first-child {
  margin-top: 0;
}
#new_ticket_form_container #new_ticket_form_header {
  position: absolute;
  top: 0;
  line-height: 82px;
  margin-left: 2em;
}
#new_ticket_form_container #new_ticket_form_header h2 {
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0 0 0.6em 0;
  vertical-align: bottom;
}
#ticket_list {
  height: 100%;
}
#ticket_list .ticket-list-assigned-to {
  vertical-align: middle;
  white-space: nowrap;
}
#ticket_search_form {
  margin: 1em 2em 0 2em;
}
#ticket_search_form .search-field {
  background: white;
  border: 1px #AAA solid;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
  position: relative;
  width: 100%;
  white-space: nowrap;
}
#ticket_search_form .search-field input {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  background: transparent;
  border: 0;
  box-sizing: border-box;
  font-size: inherit;
  margin: 0;
  padding: 6px;
  outline: 0;
}
#ticket_search_form .search-field input::-webkit-search-decoration,
#ticket_search_form .search-field input::-webkit-search-cancel-button {
  display: none;
}
#ticket_search_form .search-field .search-filter {
  background: #ECF7FF;
  border-right: 1px #BBB solid;
  display: inline-block;
  padding: 6px;
  border-radius: 6px 0 0 6px;
}
#ticket_search_form .search-field .search-filter .fa-search {
  color: #999;
  text-align: center;
  width: 15px;
}
#ticket_search_form .search-field .search-filter .selectboxit-container {
  vertical-align: middle;
}
#ticket_search_form .search-field .search-filter .selectboxit {
  background: transparent;
  color: #666;
  border: 0;
  margin: 0;
  padding: 0 1px;
}
#ticket_search_form .search-field .search-filter .selectboxit,
#ticket_search_form .search-field .search-filter .selectboxit * {
  font: inherit;
  height: 100%;
  line-height: inherit;
}
#ticket_search_form .search-field .search-filter .selectboxit .selectboxit-arrow-container {
  color: #666;
  height: 12px;
  line-height: 100%;
  width: 10px;
}
#ticket_search_form .search-field .search-filter .selectboxit .selectboxit-arrow {
  border-top-color: #666;
}
#ticket_search_form .search-field .search-filter .selectboxit-options {
  min-width: 80px;
}
#ticket_search_form .search-field .search-filter .selectboxit-options,
#ticket_search_form .search-field .search-filter .selectboxit-options * {
  font-size: inherit;
}
#ticket_search_form .search-field .search-filter select {
  display: none;
}
#ticket_search_form .search-field .search-filter .select-container {
  padding-right: 16px;
}
#ticket_search_form .search-field .search-filter .select-container select {
  background: none;
  border: 0;
  box-shadow: none;
  display: inline-block;
  font-size: inherit;
  margin: 0;
  padding: 0;
  -moz-appearance: none;
}
#ticket_search_form .search-field .search-filter .select-container select:after {
  content: "v";
  width: 16px;
  height: 16px;
  display: inline-block;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #ticket_search_form .search-field .search-filter {
    display: block;
    border-bottom: 1px #BBB solid;
    border-right: 0;
    border-radius: 6px 6px 0 0;
  }
}
.attachment-container {
  width: 400px;
}
.attachment-container .attachment-caption {
  color: blue;
  display: inline-block;
  font-weight: bold;
  margin: 0 0.5em 0.5em 0;
  text-decoration: none;
}
.attachment-container .attachment-thumbnail {
  max-width: 100%;
  max-height: 150px;
  overflow: hidden;
}
.attachment-container .attachment-controls {
  clear: both;
}
.attachment-container .attachment-controls-right {
  display: inline-block;
  float: right;
}
.attachment-container .delete-attachment-link {
  color: black;
  font-size: 1.5em;
}
.gravatar-container {
  background: #FFF;
  border: 2px #CCC dotted;
  border-radius: 50%;
}
.gravatar-container img {
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #FFF;
  box-sizing: border-box;
}
.ticket-container .gravatar-container {
  display: inline-block;
  float: left;
  overflow: hidden;
  width: 48px;
  height: 48px;
}
.ticket-container .gravatar-container img {
  vertical-align: middle;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-container .ticket-container {
    padding-left: 0;
    padding-right: 0;
  }
  .ticket-container .gravatar-container {
    display: block;
    float: none;
    text-align: center;
  }
}
.ticket {
  display: inline-block;
  display: block;
  padding: 2em;
  padding-top: 1em;
}
.ticket:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
.ticket #page_header_members {
  background: #e1e1e1;
  border-top-color: #cccccc;
  margin: 0;
}
.ticket #ticket-main {
  padding: 2em 2em 0 2em;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket #ticket-main {
    padding: 0;
  }
}
.ticket .ticket-details {
  border-bottom: 1px #DDD solid;
  padding: 1em 0 2em 0;
  width: 100%;
}
.ticket .ticket-details a {
  color: blue;
  text-decoration: none;
}
.ticket .ticket-details td,
.ticket .ticket-details th {
  padding: 1em 0;
  vertical-align: top;
}
.ticket .ticket-details td {
  text-align: left;
}
.ticket .ticket-details th {
  font-weight: normal;
  padding-right: 0.5em;
  text-align: right;
  white-space: nowrap;
  color: black;
}
.ticket .ticket-header h2 {
  font-size: 120%;
  font-weight: normal;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket .ticket-header {
    margin: 0;
    padding: 0;
  }
}
.ticket .ticket-header-details {
  margin: 0 2em 0 2em;
  padding-left: 1em;
}
.ticket .ticket-header-details .ticket-id-label {
  float: left;
  padding-right: 0.5em;
}
.ticket .ticket-header-details .ticket-summary {
  display: block;
  overflow: hidden;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket .ticket-header-details {
    margin: 0;
    padding: 0;
  }
}
.ticket .ticket-reporter {
  font-size: 90%;
  padding-bottom: 0;
}
.ticket .ticket-reporter a {
  color: blue;
  text-decoration: none;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket .ticket-reporter {
    font-size: 100%;
    line-height: 1.5;
  }
  .ticket .ticket-reporter small {
    display: block;
  }
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket {
    padding: 2em 0 0 0;
  }
}
.ticket-activity-list {
  margin: 0 2em 2em 0;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity-list {
    margin: 0;
  }
}
.ticket-activity-container {
  position: relative;
}
.ticket-activity-container .gravatar-container {
  position: absolute;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity-container .gravatar-container {
    z-index: 1;
  }
  .ticket-activity-container .gravatar-container img {
    width: 24px;
    height: 24px;
    margin: 8px 0 0 8px;
  }
}
.ticket-activity {
  background: white;
  border: 1px #c7c7c7 solid;
  border-radius: 6px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 2em;
  margin-left: 64px;
  position: relative;
}
.ticket-activity:before,
.ticket-activity:after {
  position: absolute;
  left: -20px;
  top: 9px;
  right: 100%;
  border: 1px transparent solid;
  content: " ";
  display: block;
  width: 0;
  height: 0;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity:before,
  .ticket-activity:after {
    display: none;
  }
}
.ticket-activity:before {
  border-right-color: #c7c7c7;
  border-width: 10px;
}
.ticket-activity:after {
  border-width: 9px;
  border-right-color: #ededed;
  margin-top: 1px;
  margin-left: 2px;
}
.ticket-activity .ticket-activity-private-warning {
  display: inline-block;
}
.ticket-activity.ticket-activity-draft {
  background: #ECF7FF;
  border-color: #BBB;
}
.ticket-activity.ticket-activity-draft:after {
  border-right-color: #d7ecfb;
}
.ticket-activity.ticket-activity-draft.ticket-activity-private .ticket-activity-body {
  background: #ECF7FF;
}
.ticket-activity.ticket-activity-draft.ticket-activity-private .ticket-activity-private-warning {
  display: inline-block;
}
.ticket-activity.ticket-activity-draft .toggle-public-activity {
  color: black;
}
.ticket-activity.ticket-activity-draft .ticket-activity-private-warning {
  display: none;
}
.ticket-activity.ticket-activity-draft .ticket-activity-body {
  border-top: 1px solid #BBB;
}
.ticket-activity.ticket-activity-draft .ticket-activity-header {
  background: #d7ecfb;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 0;
}
.ticket-activity.ticket-activity-draft .ticket-activity-header .fa-lock,
.ticket-activity.ticket-activity-draft .ticket-activity-header .fa-unlock-alt {
  cursor: pointer;
}
.ticket-activity.ticket-activity-draft .ticket-activity-header .ticket-activity-header-bg-default,
.ticket-activity.ticket-activity-draft .ticket-activity-header .ticket-activity-header-bg-private {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ticket-activity.ticket-activity-draft .ticket-activity-header .ticket-activity-header-bg-default {
  z-index: 3;
}
.ticket-activity.ticket-activity-draft .ticket-activity-header .ticket-activity-header-bg-private {
  background: #f2f2f2;
  top: 100%;
  z-index: 4;
  background-color: #fefe9c;
  background-image: repeating-linear-gradient(-45deg, #fefe9c, #fefe9c 10px, #f3f3a5 10px, #f3f3a5 20px);
}
.ticket-activity.ticket-activity-draft .ticket-activity-header .ticket-activity-header-bg-private:after {
  border-right-color: #fefe9c;
}
.ticket-activity.ticket-activity-draft .ticket-activity-header .ticket-activity-header-fg {
  padding: 1em;
  position: relative;
  z-index: 4;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity.ticket-activity-draft .ticket-activity-header .ticket-activity-header-fg {
    padding-left: 36px;
  }
}
.ticket-activity.ticket-activity-draft .ticket-activity-update-fields-action.disabled a {
  color: grey;
  cursor: default;
}
.ticket-activity.ticket-activity-draft .ticket-activity-update-fields-action.disabled a:hover {
  text-decoration: none;
}
.ticket-activity.ticket-activity-private {
  background: #f2f2f2;
}
.ticket-activity.ticket-activity-private:after {
  border-right-color: #fefe9c;
}
.ticket-activity.ticket-activity-private .ticket-activity-header {
  background-color: #fefe9c;
  background-image: repeating-linear-gradient(-45deg, #fefe9c, #fefe9c 10px, #f3f3a5 10px, #f3f3a5 20px);
}
.ticket-activity .ticket-activity-body {
  margin: 0;
  padding: 1.5em;
}
.ticket-activity .ticket-activity-body textarea {
  box-sizing: border-box;
  min-height: 102px;
  width: 100%;
}
.ticket-activity .ticket-activity-body .ticket-activity-buttons button {
  margin-right: 0.5em;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity .ticket-activity-body .ticket-activity-buttons .ticket-send-email-field {
    display: block;
    margin-top: 1em;
    text-align: center;
  }
}
.ticket-activity .ticket-activity-body .ticket-activity-panels {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li {
  margin: 0 0 1.5em 0;
  padding: 0;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel {
  display: block;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-out, margin 0.1s linear, opacity 0.35s ease-out;
  opacity: 0;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.closing {
  max-height: 0 !important;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.open {
  transition: max-height 0.2s ease-in, margin 0.1s linear, opacity 0.4s ease-in;
  margin-bottom: 1.5em;
  opacity: 1;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.fully-open {
  max-height: none;
  overflow: visible;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-attach-files .splat-dropzone-area {
  margin: 0;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-attach-files .splat-dropzone-area .dz-message {
  padding: 2em 0;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields {
  margin-left: 1.5em;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields td,
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields th {
  padding: 0.4em 0;
  vertical-align: top;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields td {
  padding-left: 0.5em;
  width: 100%;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields td input {
  box-sizing: border-box;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields td #id_summary {
  /* Compensate for extra spacing below selectize inputs. */
  margin-bottom: 3px;
  width: 100%;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields td .selectize-control.assigned-to-field .gravatar,
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields td .selectize-control.assigned-to-field .gravatar-retina {
  border-radius: 50%;
  /*
                   * Adjust to help balance the image. Otherwise it's too far
                   * down.
                   */
  margin-top: -4px;
  margin-bottom: -3px;
  vertical-align: middle;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields th {
  line-height: 18px;
  text-align: right;
  white-space: nowrap;
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields th label {
  display: inline-block;
  margin-top: 7px;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields {
    margin-left: 0;
  }
  .ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields td,
  .ticket-activity .ticket-activity-body .ticket-activity-panels li.ticket-activity-update-panel.ticket-activity-update-fields th {
    display: block;
    padding: 0;
    text-align: left;
  }
}
.ticket-activity .ticket-activity-body .ticket-activity-panels li > a .fa {
  font-size: 120%;
  margin-right: 0.3em;
}
.ticket-activity .ticket-activity-fields-changed {
  background: #FFFFF6;
  border-top: 1px #DDDDD6 solid;
  border-radius: 0 0 6px 6px;
  list-style: none;
  margin: 0;
  padding: 1.5em;
  /* Field labels */
  /* Displays changes for field values, with "-" and "+" markers. */
}
.ticket-activity .ticket-activity-fields-changed label {
  color: #111;
  display: inline-block;
  font-weight: normal;
  margin: 0 1em 0.5em 0;
  padding: 0;
  min-width: 7em;
  vertical-align: top;
  text-align: right;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity .ticket-activity-fields-changed label {
    display: block;
    min-width: 0;
    text-align: left;
  }
}
.ticket-activity .ticket-activity-fields-changed li {
  margin-top: 1em;
  margin-bottom: 0;
  vertical-align: top;
}
.ticket-activity .ticket-activity-fields-changed li:first-child {
  margin-top: 0;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity .ticket-activity-fields-changed li {
    margin-top: 1.5em;
  }
}
.ticket-activity .ticket-activity-fields-changed .changed {
  border: 1px #CCC solid;
  border-collapse: collapse;
  border-radius: 8px;
  display: inline-block;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  vertical-align: top;
}
.ticket-activity .ticket-activity-fields-changed .changed a {
  color: blue;
  text-decoration: none;
}
.ticket-activity .ticket-activity-fields-changed .changed a:first-child {
  margin-left: 0;
}
.ticket-activity .ticket-activity-fields-changed .changed a:hover {
  text-decoration: underline;
}
.ticket-activity .ticket-activity-fields-changed .changed td,
.ticket-activity .ticket-activity-fields-changed .changed th {
  font-family: monospace;
  padding: 0.3em 0.6em;
}
.ticket-activity .ticket-activity-fields-changed .changed td {
  background: #FEFEFE;
}
.ticket-activity .ticket-activity-fields-changed .changed .marker {
  vertical-align: top;
}
.ticket-activity .ticket-activity-fields-changed .changed .new-value .marker {
  background: #caf2c1;
}
.ticket-activity .ticket-activity-fields-changed .changed .old-value .marker {
  background: #f5d0d6;
}
.ticket-activity .ticket-activity-header {
  background: #ededed;
  border-radius: 6px 6px 0 0;
  padding: 1em;
}
.ticket-activity .ticket-activity-header a {
  text-decoration: none;
}
.ticket-activity .ticket-activity-header .fa-lock,
.ticket-activity .ticket-activity-header .fa-unlock-alt {
  font-size: 1.5em;
  line-height: normal;
  margin-left: 0.5em;
  vertical-align: middle;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity .ticket-activity-header {
    padding-left: 36px;
  }
  .ticket-activity .ticket-activity-header small {
    display: block;
    margin-top: 1em;
  }
}
.ticket-activity .ticket-activity-parent {
  margin-bottom: 1em;
}
.ticket-activity .ticket-activity-parent a {
  color: #212121;
}
.ticket-activity .ticket-activity-right {
  float: right;
  line-height: 1em;
}
.ticket-activity .ticket-activity-right .ticket-activity-timestamp,
.ticket-activity .ticket-activity-right .ticket-activity-delete a {
  color: #666;
}
.ticket-activity .ticket-activity-right .ticket-activity-delete {
  font-size: 1.5em;
  margin-left: 0.5em;
}
.ticket-activity .ticket-activity-right .ticket-activity-delete .fa {
  vertical-align: middle;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-activity {
    margin-left: 0;
  }
}
.ticket-labels {
  display: block;
  font-size: 90%;
  list-style: none;
  margin: 1.5em 0 0 0;
  padding: 0;
}
.ticket-labels li {
  display: inline;
  margin: 0;
  padding: 0;
}
.ticket-labels li .ticket-project,
.ticket-labels li .ticket-milestone,
.ticket-labels li .ticket-status,
.ticket-labels li .ticket-tag {
  border: 1px #DDD solid;
  border-radius: 10px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.3);
  display: inline-block;
  font-size: 12px;
  margin: 1em 1em 0 0;
  padding: 4px 10px;
}
.ticket-labels li .ticket-project a,
.ticket-labels li .ticket-milestone a,
.ticket-labels li .ticket-status a,
.ticket-labels li .ticket-tag a {
  color: inherit;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket-labels li .ticket-project,
  .ticket-labels li .ticket-milestone,
  .ticket-labels li .ticket-status,
  .ticket-labels li .ticket-tag {
    margin: 0.5em 0.2em 0 0;
  }
}
.ticket-labels li .ticket-project {
  border: 1px #DDD solid;
}
.ticket-tags-column .ticket-labels {
  margin: 0;
  white-space: nowrap;
}
.ticket-tags-column .ticket-labels .ticket-tag {
  margin: -5px 4px -5px 0;
}
.ticket-tags-column .ticket-labels .ticket-tag a {
  color: inherit !important;
}
/* Rules common to both tickets and activity on a ticket. */
.ticket pre,
.ticket-activity pre {
  font-family: DejaVu Sans Mono, monospace;
  font-size: 12px;
  line-height: 17px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .ticket pre,
  .ticket-activity pre {
    font-size: 11px;
  }
}
.main-field-content {
  margin: 1.5em 1.5em 0 1.5em;
}
.main-field-content:last-child {
  padding-bottom: 1.5em;
}
.main-field-content .textarea-label {
  display: block;
  font-weight: bold;
  font-size: 110%;
  margin-bottom: 1em;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  .main-field-content {
    margin: 1.5em 0 0 0;
  }
}
.rich-text {
  box-sizing: border-box;
  line-height: 15px;
}
.rich-text * {
  margin: 0;
  padding: 0;
  line-height: inherit;
  text-rendering: inherit;
  white-space: normal;
}
.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5,
.rich-text h6 {
  font-size: 100%;
}
.rich-text p {
  white-space: inherit;
}
.rich-text ul,
.rich-text ol {
  line-height: 0;
  margin: 0;
  padding: 0;
}
.rich-text ul ul,
.rich-text ol ul,
.rich-text ul ol,
.rich-text ol ol {
  margin-left: 2ch;
}
.rich-text ul:last-child li:last-child p:last-child,
.rich-text ol:last-child li:last-child p:last-child {
  margin-bottom: 0;
}
.rich-text ol {
  list-style-position: inside;
}
.rich-text ul {
  list-style-position: outside;
  list-style: disc;
  margin-left: 2ch;
}
.rich-text ol + pre,
.rich-text ul + pre,
.rich-text pre + pre {
  /*
       * There are two blank lines between lists and code blocks, which won't
       * otherwise be preserved. Try to keep that same spacing.
       */
  margin-top: 15px;
}
.rich-text li {
  line-height: 15px;
}
.rich-text li li:first-child {
  margin-top: 15px;
}
.rich-text li p {
  margin-bottom: 15px;
}
.rich-text table th,
.rich-text table td {
  border-bottom: 1px solid #bbb;
  padding: 0.2em 1em;
}
.rich-text code {
  color: #4444cc;
}
.rich-text pre {
  white-space: pre-wrap;
}
.rich-text pre * {
  white-space: pre-wrap;
}
.rich-text pre code {
  border: none;
  box-sizing: border-box;
  color: black;
  display: block;
  margin-left: 2.5em;
}
.rich-text pre code .linenums {
  /* On Firefox, we'll be one pixel too far to the right, so adjust it. */
  -moz-margin-start: -1px;
}
.rich-text pre code .linenums li {
  list-style: none;
  margin: 0;
}
.rich-text pre code .linenums li p {
  margin: 0;
}
.rich-text blockquote {
  border-left: 1px solid #bbb;
  padding-left: 1em;
  margin-left: 0.5em;
}
.selectboxit-arrow-container {
  /*
   * Reduce the width of the container, to help shorten the menu size and
   * reduce the gap between the arrow and text. This must be done in a
   * global rule, or it won't affect selectBoxIt's calculations.
   */
  width: 12px;
}
.subscribe-btn {
  margin-top: 2em;
}
/*# sourceMappingURL=tickets.css.map */