body {
  background: #FCFCFC;
  color: #111;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  font-size: 9pt;
  margin: 0;
  padding: 0;
}
body.full-page-content {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
body.full-page-content #content {
  height: 100%;
}
body.full-page-content #page_container {
  height: 100%;
}
html {
  height: 100%;
}
#banner {
  background-color: #feffc9;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 0;
  padding: 0.25em 2em;
}
#banner a {
  color: #0000dd;
}
#banner h1 {
  display: inline-block;
  font-size: 100%;
}
a {
  color: #4444FF;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
h2 {
  margin: 0 0 1.5em 0;
}
h3 {
  font-weight: normal;
  font-size: 120%;
  margin: 3em 0 1.5em 0;
}
h3:first-child {
  margin-top: 0;
}
li {
  margin-bottom: 1.5em;
}
/*
 * The account menu area of the header bar.
 */
#account_nav {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
#account_nav li {
  border: 1px transparent solid;
  display: inline-block;
  opacity: 0.6;
  margin: 0 0 0.5em 0;
  padding: 0;
  position: relative;
  text-align: right;
  transition: 0.3s opacity linear;
  white-space: nowrap;
}
#account_nav li:hover {
  background: #FFF;
  opacity: 1;
}
#account_nav li:hover ul {
  background: #FFFFFF;
  border: 1px #cccccc solid;
  border-top-color: #a6a6a6;
  display: block;
  opacity: inherit;
  transition: 0.3s opacity linear;
  z-index: 110;
}
#account_nav li:hover ul li {
  border: 0;
  opacity: 1;
  display: block;
}
#account_nav li:hover ul li:hover {
  background: #f7f7f7;
}
#account_nav li:hover ul li a {
  padding: 0.8em 1em;
}
#account_nav li a {
  color: inherit;
  display: block;
  margin: 0;
  padding: 0 1em 0.5em 1em;
  text-decoration: none;
  vertical-align: middle;
}
#account_nav li a.user-nav-item {
  padding-left: 0.2em;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #account_nav li a.user-nav-item label {
    display: none;
  }
}
#account_nav li img {
  display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
#account_nav li label {
  vertical-align: middle;
  margin-left: 4px;
}
#account_nav li ul {
  display: none;
  opacity: 0;
  margin: 0;
  padding: 0;
  border-radius: 0 0 6px 6px;
}
#account_nav li ul li {
  float: none;
  margin: 0;
  padding: 0;
  text-align: right;
}
#account_nav li ul li:last-child {
  border-radius: 0 0 6px 6px;
}
#account_nav li ul li a {
  margin: 0;
}
#logo {
  display: inline-block;
  font-size: 12pt;
  font-weight: normal;
  margin: 0;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.3s opacity linear;
}
#logo:hover {
  opacity: 1;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #logo {
    position: relative;
  }
}
#content {
  border-top: 0;
  position: relative;
}
#header_bar {
  background-color: #FCFCFC;
  background-repeat: repeat-x;
  background-image: linear-gradient(to bottom, #FFFFFF, #FCFCFC);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#FCFCFC', GradientType=0);
  margin: 0;
  padding: 2em;
  padding-bottom: 0;
  vertical-align: middle;
}
#header_bar_primary {
  position: relative;
}
#header_bar_secondary {
  clear: both;
}
#page_actions {
  list-style: none;
  margin: 2em;
  padding: 0;
  text-align: center;
}
#page_actions > li {
  display: inline-block;
  margin: 0.5em 0 0.5em 2em;
  padding: 0;
}
#page_actions > li:first-child {
  margin-left: 0;
}
#page_actions > li:first-child ul {
  border-left: 0;
  margin-left: 0;
}
#page_actions > li:first-child ul li:first-child {
  margin-left: 0;
}
#page_actions > li a {
  font-size: 110%;
}
#page_actions > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 1px #DDD solid;
}
#page_actions > li ul li {
  display: inline-block;
  margin: 0 0 0 2em;
  padding: 0.4em 0;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #page_actions > li ul {
    border-left: 0;
  }
}
#page_actions > li .fa {
  color: #666;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #page_actions > li {
    margin-left: 0;
  }
}
#page_browse {
  list-style: none;
  margin: 0;
  padding: 0;
}
#page_browse li {
  margin-bottom: 4em;
}
#page_browse li a {
  font-size: 150%;
}
#page_browse li .browse-item-info {
  list-style: none;
  color: #666;
  margin: 1em 1em 1em 0;
  padding: 0;
}
#page_browse li .browse-item-info li {
  display: inline;
  margin-right: 1em;
}
#page_browse li .browse-item-info li a {
  color: inherit;
  font-size: inherit;
}
#page_container {
  padding: 2em;
}
#page_container h2 {
  margin-top: 0;
  padding-top: 0;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #page_container {
    padding: 1.5em;
  }
}
#page_header {
  background: #FAFAFA;
  border-bottom: 1px #c7c7c7 solid;
  padding: 2em 2em 0 2em;
}
#page_header #page_header_links {
  margin: 0;
  padding: 0;
  list-style: none;
}
#page_header #page_header_links li {
  color: #2e2e2e;
  display: inline;
  margin-right: 1em;
}
#page_header #page_header_links li a {
  color: inherit;
}
#page_header #page_header_links li .fa {
  color: #555;
}
#page_header #page_header_members {
  border-top: 1px #c7c7c7 solid;
  background: #e1e1e1;
  margin-top: 2em;
  margin-left: -2em;
  margin-right: -2em;
  padding-left: 2em;
  height: 32px;
}
#page_header_title {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
#page_header_title li {
  display: inline-block;
  margin: -0.5em 1em 0 0;
  padding: 0.5em 0;
}
#page_header_title li.separator {
  display: inline;
}
#page_header_title li a {
  color: inherit;
  margin-left: 4px;
}
#page_header_title li a:hover {
  text-decoration: none;
}
#page_header_title li a,
#page_header_title li span {
  font-size: 20px;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #page_header_title li a,
  #page_header_title li span {
    font-size: 18px;
  }
}
#page_header_title li .dropdown {
  font-size: 80%;
  vertical-align: middle;
}
#page_header_title li .fa {
  color: #CCC;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #page_header_title {
    margin-top: 1em;
  }
}
.separator {
  border-left: 1px #DDD solid;
  padding: 0;
}
/*# sourceMappingURL=base.css.map */