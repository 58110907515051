/*
 * Add borders to the landing page left, right, and bottom sides, and take
 * up the full height of the page at minimum.
 */
#landing_page {
  background: #FFF;
  border: 20px #82CD2C solid;
  border-top: 0;
  box-sizing: border-box;
  min-height: 100%;
}
#landing_page #header_bar {
  background: inherit;
}
#splat_intro {
  margin: 0 auto;
  max-width: 70em;
  /*
   * Style the e-mail sign-up form, and position the Splat bug to the
   * left of the form so that it's hanging over the text field just slightly.
   * The sign-up button will hang below the right-hand side of the field.
   *
   * On mobile, the Splat bug, sign-up button, and input field text will be
   * center-aligned, taking up the full width of the page.
   */
  /*
   * Adjust the bubbles and Splat bug to better position the bubble callout
   * to point to the mouth.
   */
  /*
   * Adjust the bubbles and Splat bug to better position the bubble callout
   * to point to the mouth.
   */
  /*
   * A section in the tour, consisting of the Splat bug, bubbles, and
   * possibly other elements.
   *
   * On desktop, the bubbles and Splat bug will align to one side or another,
   * with the bubbles staggering.
   *
   * On mobile, they'll be center-aligned, with the bubbles either above or
   * below the Splat bug.
   */
}
#splat_intro #tour_pane_email {
  display: inline-block;
  display: block;
  text-align: center;
}
#splat_intro #tour_pane_email:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
#splat_intro #tour_pane_email .bubble-group {
  margin-left: 26em;
}
#splat_intro #tour_pane_email .form-container {
  clear: both;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: -8em;
}
#splat_intro #tour_pane_email .form-container form {
  clear: both;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 35em;
}
#splat_intro #tour_pane_email .form-container form:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
#splat_intro #tour_pane_email .form-container form .above {
  margin-left: 72px;
  position: absolute;
  left: 0;
  top: 109px;
}
#splat_intro #tour_pane_email .form-container form .below {
  display: inline-block;
}
#splat_intro #tour_pane_email .form-container form .email-box {
  display: inline-block;
  position: absolute;
  top: 120px;
  left: 0;
  width: 32em;
  padding-left: 3em;
}
#splat_intro #tour_pane_email .form-container form .email-box:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
#splat_intro #tour_pane_email .form-container form .email-box button {
  background: #FBF096;
  border: 1px #716403 solid;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: none;
  color: #111;
  float: right;
  font-size: 140%;
  background-color: #F6DE18;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, #FBEF96, #FBEF96 5%, #F6DE18);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FBEF96', endColorstr='#F6DE18', GradientType=0);
  margin-right: 1.5em;
  outline: 0;
}
#splat_intro #tour_pane_email .form-container form .email-box button:hover {
  background-color: #f8e43f;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, #fcf5bd, #fcf5bd 5%, #f8e43f);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcf5bd', endColorstr='#f8e43f', GradientType=0);
}
#splat_intro #tour_pane_email .form-container form .email-box input {
  border: 2px #5E911F solid;
  border-radius: 2em;
  box-sizing: border-box;
  width: 100%;
  font-size: 120%;
  margin: 0;
  padding: 0.8em 1em;
  outline: 0;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #splat_intro #tour_pane_email .bubble-group {
    margin-left: 0;
  }
  #splat_intro #tour_pane_email .form-container {
    margin-top: 0;
  }
  #splat_intro #tour_pane_email .form-container form {
    text-align: center;
    width: 100%;
  }
  #splat_intro #tour_pane_email .form-container form .email-box {
    padding-left: 0;
    width: 100%;
  }
  #splat_intro #tour_pane_email .form-container form .email-box button,
  #splat_intro #tour_pane_email .form-container form .email-box input {
    float: none;
    margin: 0;
    text-align: center;
  }
  #splat_intro #tour_pane_email .form-container form .above {
    /*
             * Try to position the mouth over the center-aligned body.
             */
    left: 50%;
    margin-left: -34px;
  }
}
#splat_intro #tour_pane_private_tickets img {
  margin-top: 3em;
}
#splat_intro #tour_pane_private_tickets .bubble-group {
  margin-left: -1em;
}
#splat_intro #tour_pane_private_tickets .bubble-group .bubble-content::before {
  /*
         * Adjust the height of the white box for the callout to better cover
         * where the callout meets the bubble.
         */
  height: 12px;
}
#splat_intro #tour_pane_works_with_rb img {
  margin-top: 2em;
}
#splat_intro #tour_pane_works_with_rb .bubble-group {
  margin-right: -0.5em;
}
#splat_intro .tour-pane {
  display: inline-block;
  display: block;
  margin-top: 8em;
  /*
     * Centered panes stack the bubbles in the center and have a callout on
     * the last bubble pointing downward
     */
  /*
     * Bottom-left panes stack the bubbles and have a callout on the
     * bottom-most bubble pointing toward the bottom-left.
     */
  /*
     * Left panes stagger the bubbles and have a callout on the top-most
     * bubble pointing toward the bottom-left.
     */
  /*
     * Right panes stagger the bubbles and have a callout on the top-most
     * bubble pointing toward the bottom-right
     */
  /*
     * Manages a stack of bubbles.
     *
     * Each bubble will be merged together so that the edges of the bubbles are
     * connected and the white bubble interiors are connected, without borders
     * separating the bubble contents themselves. This is done by having the
     * borders set on the .bubble elements, while the backgrounds are set
     * higher up on the .bubble-content elements.
     */
}
#splat_intro .tour-pane:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
#splat_intro .tour-pane:first-child {
  margin-top: 5em;
}
#splat_intro .tour-pane.tour-pane-centered {
  text-align: center;
}
#splat_intro .tour-pane.tour-pane-centered img {
  display: inline-block;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble {
  display: inline-block;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child {
  position: relative;
  /*
   * Rules for displaying a bottom-left callout.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-right callout.
   *
   * This is simply the inverse of the bottom-left callout.
   */
  /*
   * Rules for displaying a bottom-left callout on the last bubble.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-center callout.
   *
   * These are a bit different from the bottom-left/right callouts, in that
   * both sides are curved inward to a single point.
   */
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before {
  box-sizing: border-box;
  z-index: -1;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:after {
  width: 30px;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:before {
  width: 30px;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:before {
  content: "";
  display: block;
  height: 25px;
  position: absolute;
  top: 100%;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:before {
  /*
     * Cover the bubble's border, where it intersects with the callout.
     *
     * We pick a height of 10px, which is enough to cover the border in all
     * common cases, without causing problems with the callout border.
     */
  background: white;
  height: 10px;
  width: -4px;
  border: 0;
  border-radius: 0;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after {
  border-right: 2px #5E5E5E solid;
  left: 50%;
  margin-left: -30px;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before {
  border-left: 2px #5E5E5E solid;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before {
  width: 30px;
  /*
     * Place this higher than the bubble background and callout background,
     * to help cover up the border area on the bubble but not the callout.
     */
  z-index: 2;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:before {
  height: 25px;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:before {
  margin-top: -1px;
  margin-left: -2px;
  left: 50%;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:before {
  height: 10px;
  width: 58px;
  margin-left: -30px;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after {
  border-top: 2px #5E5E5E solid;
  border-radius: 0 100% 0 0;
  margin-top: -1px;
  border-bottom: 0;
  border-left: 0;
  margin-left: -30px;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before {
  border-top: 2px #5E5E5E solid;
  border-bottom: 0;
  border-right: 0;
  border-radius: 100% 0 0 0;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child:before {
  /*
     * Switch to bottom-based positioning, to fix some Chrome calculation
     * errors.
     */
  top: auto;
  bottom: -24px;
}
#splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble:last-child .bubble-content:before {
  top: 100%;
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child {
  position: relative;
  /*
   * Rules for displaying a bottom-left callout.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-right callout.
   *
   * This is simply the inverse of the bottom-left callout.
   */
  /*
   * Rules for displaying a bottom-left callout on the last bubble.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-center callout.
   *
   * These are a bit different from the bottom-left/right callouts, in that
   * both sides are curved inward to a single point.
   */
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before {
  box-sizing: border-box;
  z-index: -1;
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:after {
  width: 35px;
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before,
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
  width: 60px;
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before,
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
  content: "";
  display: block;
  height: 35px;
  position: absolute;
  top: 80%;
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
  /*
     * Cover the bubble's border, where it intersects with the callout.
     *
     * We pick a height of 10px, which is enough to cover the border in all
     * common cases, without causing problems with the callout border.
     */
  background: white;
  height: 10px;
  width: 21px;
  border: 0;
  border-radius: 0;
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after,
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before {
  border-bottom: 2px #5E5E5E solid;
  border-right: 2px #5E5E5E solid;
  border-left: 2px transparent solid;
  border-top: 2px transparent solid;
  border-radius: 100% 0;
  left: 0px;
}
#splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
  left: 35px;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after {
    border-right: 2px #5E5E5E solid;
    left: 50%;
    margin-left: -30px;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before {
    border-left: 2px #5E5E5E solid;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after,
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before {
    width: 30px;
    /*
     * Place this higher than the bubble background and callout background,
     * to help cover up the border area on the bubble but not the callout.
     */
    z-index: 2;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after,
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before,
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
    height: 25px;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before,
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
    margin-top: -1px;
    margin-left: -2px;
    left: 50%;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
    height: 10px;
    width: 58px;
    margin-left: -30px;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after {
    border-top: 2px #5E5E5E solid;
    border-radius: 0 100% 0 0;
    margin-top: -1px;
    border-bottom: 0;
    border-left: 0;
    margin-left: -30px;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before {
    border-top: 2px #5E5E5E solid;
    border-bottom: 0;
    border-right: 0;
    border-radius: 100% 0 0 0;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:after,
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child:before {
    /*
     * Switch to bottom-based positioning, to fix some Chrome calculation
     * errors.
     */
    top: auto;
    bottom: -24px;
  }
  #splat_intro .tour-pane.tour-pane-bottom-left .bubble-group .bubble:last-child .bubble-content:before {
    top: 100%;
  }
}
#splat_intro .tour-pane.tour-pane-left img {
  float: left;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group {
  float: left;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble {
  clear: left;
  float: left;
  margin-left: 2em;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child {
  margin-left: 0;
  position: relative;
  /*
   * Rules for displaying a bottom-left callout.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-right callout.
   *
   * This is simply the inverse of the bottom-left callout.
   */
  /*
   * Rules for displaying a bottom-left callout on the last bubble.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-center callout.
   *
   * These are a bit different from the bottom-left/right callouts, in that
   * both sides are curved inward to a single point.
   */
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before {
  box-sizing: border-box;
  z-index: -1;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:after {
  width: 35px;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before,
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
  width: 60px;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before,
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
  content: "";
  display: block;
  height: 35px;
  position: absolute;
  top: 80%;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
  /*
     * Cover the bubble's border, where it intersects with the callout.
     *
     * We pick a height of 10px, which is enough to cover the border in all
     * common cases, without causing problems with the callout border.
     */
  background: white;
  height: 10px;
  width: 21px;
  border: 0;
  border-radius: 0;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before {
  border-bottom: 2px #5E5E5E solid;
  border-right: 2px #5E5E5E solid;
  border-left: 2px transparent solid;
  border-top: 2px transparent solid;
  border-radius: 100% 0;
  left: 0px;
}
#splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
  left: 35px;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after {
    border-right: 2px #5E5E5E solid;
    left: 50%;
    margin-left: -30px;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before {
    border-left: 2px #5E5E5E solid;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after,
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before {
    width: 30px;
    /*
     * Place this higher than the bubble background and callout background,
     * to help cover up the border area on the bubble but not the callout.
     */
    z-index: 2;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after,
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before,
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
    height: 25px;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before,
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
    margin-top: -1px;
    margin-left: -2px;
    left: 50%;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
    height: 10px;
    width: 58px;
    margin-left: -30px;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after {
    border-top: 0;
    border-bottom: 2px #5E5E5E solid;
    border-left: 0;
    border-radius: 0 0 100% 0;
    margin-top: -1px;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before {
    border-top: 0;
    border-bottom: 2px #5E5E5E solid;
    border-right: 0;
    border-radius: 0 0 0 100%;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:after,
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child:before {
    /*
     * Switch to bottom-based positioning, to fix some Chrome calculation
     * errors.
     */
    top: -23px;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble:first-child .bubble-content:before {
    top: -8px;
  }
}
#splat_intro .tour-pane.tour-pane-right img {
  float: right;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group {
  float: right;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble {
  clear: right;
  float: right;
  margin-right: 2em;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child {
  margin-right: 0;
  position: relative;
  /*
   * Rules for displaying a bottom-left callout.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-right callout.
   *
   * This is simply the inverse of the bottom-left callout.
   */
  /*
   * Rules for displaying a bottom-left callout on the last bubble.
   *
   * This displays a curve pointing from the bubble to the bottom-left.
   */
  /*
   * Rules for displaying a bottom-center callout.
   *
   * These are a bit different from the bottom-left/right callouts, in that
   * both sides are curved inward to a single point.
   */
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before {
  box-sizing: border-box;
  z-index: -1;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:after {
  width: 35px;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before,
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
  width: 60px;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before,
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
  content: "";
  display: block;
  height: 35px;
  position: absolute;
  top: 80%;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
  /*
     * Cover the bubble's border, where it intersects with the callout.
     *
     * We pick a height of 10px, which is enough to cover the border in all
     * common cases, without causing problems with the callout border.
     */
  background: white;
  height: 10px;
  width: 21px;
  border: 0;
  border-radius: 0;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after,
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before {
  border-bottom: 2px #5E5E5E solid;
  border-left: 2px #5E5E5E solid;
  border-right: 2px transparent solid;
  border-top: 2px transparent solid;
  border-radius: 0 100%;
  right: 0px;
}
#splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
  right: 35px;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after {
    border-right: 2px #5E5E5E solid;
    left: 50%;
    margin-left: -30px;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before {
    border-left: 2px #5E5E5E solid;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after,
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before {
    width: 30px;
    /*
     * Place this higher than the bubble background and callout background,
     * to help cover up the border area on the bubble but not the callout.
     */
    z-index: 2;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after,
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before,
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
    height: 25px;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before,
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
    margin-top: -1px;
    margin-left: -2px;
    left: 50%;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
    height: 10px;
    width: 58px;
    margin-left: -30px;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after {
    border-top: 0;
    border-bottom: 2px #5E5E5E solid;
    border-left: 0;
    border-radius: 0 0 100% 0;
    margin-top: -1px;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before {
    border-top: 0;
    border-bottom: 2px #5E5E5E solid;
    border-right: 0;
    border-radius: 0 0 0 100%;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:after,
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child:before {
    /*
     * Switch to bottom-based positioning, to fix some Chrome calculation
     * errors.
     */
    top: -23px;
  }
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble:first-child .bubble-content:before {
    top: -8px;
  }
}
#splat_intro .tour-pane .bubble-group {
  display: inline-block;
  max-width: 30em;
}
#splat_intro .tour-pane .bubble-group .bubble {
  border: 2px #5E5E5E solid;
  border-radius: 50%;
  font-family: "Comic Neue";
  font-size: 13pt;
  line-height: 1.5;
  margin: -1em 0 0 0;
  padding: 0;
  position: relative;
  text-align: center;
}
#splat_intro .tour-pane .bubble-group .bubble:first-child {
  margin-top: 0;
}
#splat_intro .tour-pane .bubble-group .bubble .bubble-content {
  background: white;
  border-radius: inherit;
  max-width: 15em;
  padding: 8% 2.5em;
  position: relative;
  z-index: 1;
}
#splat_intro .tour-pane .bubble-group .bubble .bubble-content a {
  font-weight: bold;
}
#splat_intro .tour-pane .bubble-group .bubble .bubble-content p {
  margin: 1em 0 0 0;
}
#splat_intro .tour-pane .bubble-group .bubble .bubble-content p:first-child {
  margin-top: 0;
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #splat_intro .tour-pane .bubble-group .bubble {
    font-size: 11pt;
  }
}
@media screen and (max-width: 720px), screen and (max-device-width: 720px) and (orientation: landscape) {
  #splat_intro .tour-pane {
    text-align: center;
  }
  #splat_intro .tour-pane:first-child {
    margin-top: 0;
  }
  #splat_intro .tour-pane.tour-pane-left img,
  #splat_intro .tour-pane.tour-pane-right img,
  #splat_intro .tour-pane.tour-pane-centered img,
  #splat_intro .tour-pane.tour-pane-left .bubble-group,
  #splat_intro .tour-pane.tour-pane-right .bubble-group,
  #splat_intro .tour-pane.tour-pane-centered .bubble-group {
    display: inline-block;
    float: none;
  }
  #splat_intro .tour-pane.tour-pane-left .bubble-group .bubble,
  #splat_intro .tour-pane.tour-pane-right .bubble-group .bubble,
  #splat_intro .tour-pane.tour-pane-centered .bubble-group .bubble {
    margin-left: 0;
    margin-right: 0;
  }
  #splat_intro .tour-pane.tour-pane-left img {
    padding-right: 3em;
  }
  #splat_intro .tour-pane.tour-pane-right img {
    padding-left: 3em;
  }
}
/*# sourceMappingURL=landing.css.map */